<template>
    <div>
        <!-- 豆腐块 -->
        <div class="tofu block-item-bottom">
            <div class="tofu-L">
                <template>
                    <li :class="params.taskType == item.value ? 'tofu-item-active' : 'tofu-item'"
                        @click="onTofu(item.value)" v-for="item in tofuOpt" :key="item.value">
                        <span>{{ item.label }}</span>
                        <em>{{ item.count }}</em>
                    </li>
                </template>
            </div>
            <div class="tofu-R my-right" v-if="params.taskType!=1">
                <el-date-picker v-model="startDateToDate" type="daterange" align="center" unlink-panels
                    range-separator="~" start-placeholder="发起时间" end-placeholder="发起时间" @change="startDateChange"
                    clearable value-format="yyyy-MM-dd">
                </el-date-picker>
            </div>
        </div>
        <!-- 表格列表 -->
        <div class="block-item-bottom">
            <div class="search">
                <el-row :gutter="10" style="margin-bottom: 10px;">
                    <el-col :span="4">
                        <el-input placeholder="请输入发起人搜索" v-model="params.applyName" clearable @input="kwChange">
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-input placeholder="请输入当前审批人搜索" v-model="params.executeName" clearable @input="kwChange">
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-input placeholder="请输入工单编号搜索" v-model="params.sheetCode" clearable @input="kwChange">
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <el-input placeholder="请输入摘要内容搜索" v-model="params.summary" clearable @input="kwChange">
                        </el-input>
                    </el-col>
                    <el-col :span="4">
                        <div class="search-inp">
                            <el-select v-model="params.approveStatus" @change="onTableReload()" placeholder="请选择审批状态"
                                clearable>
                                <el-option v-for="item in ObjToOpt(approveStatusObj)" :label="item.label"
                                    :value="item.value" :style="{ 'color': approveStatusColor[item.value] }"
                                    :key="item.value">
                                </el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :span="4">
                        <el-select v-model="params.workSheetTypeId" placeholder="选择工单类型" @change="onTableReload()"
                            clearable>
                            <el-option v-for="(item, index) in workSheetTypeList" :key="index"
                                :label="item.workSheetName" :value="item.workSheetTypeId">
                            </el-option>
                        </el-select>
                    </el-col>
                </el-row>
                <el-row :gutter="10" style="width: 100%;">
                    <el-col :span="4">
                        <el-cascader v-model="params.deptIds" :options="departmentList" placeholder="请选择发起人部门"
                            :props="props" @change="onTableReload"></el-cascader>
                    </el-col>
                    <el-col :span="6">
                        <el-date-picker v-model="doneDateToDate" type="daterange" align="center" unlink-panels
                            range-separator="~" start-placeholder="完成时间" end-placeholder="完成时间" @change="doneDateChange"
                            clearable value-format="yyyy-MM-dd">
                        </el-date-picker>
                    </el-col>
                    <el-col :span="4" style="display: flex;">
                        <WorkSheetDialog :title="'新建常规工单'" @refresh="onTableReload" v-has="'center_add'" />
                        <el-button type="primary" @click="onExport" style="margin-left: 10px;">导出</el-button>
                    </el-col>
                </el-row>
            </div>
            <el-table :data="tableData" style="width: 100%" :headerCellStyle="headerCellStyle" border>
                <el-table-column label="工单编号" prop="sheetCode"></el-table-column>
                <el-table-column label="工单类型" prop="workSheetTypeName"></el-table-column>
                <el-table-column label="工单归属门店" prop="projectName"></el-table-column>
                <el-table-column label="发起人" prop="applyUserName"></el-table-column>
                <el-table-column label="审批摘要" show-overflow-tooltip min-width="150">
                    <template slot-scope="scope">
                        <template
                            v-if="scope.row.approveFormValueVoList && scope.row.approveFormValueVoList.length > 0">
                            <div class="remark" v-for="(item, index) in scope.row.approveFormValueVoList" :key="index">
                                <template v-if="item.inputType == 7">
                                    <span>{{ item.formItemName }}:</span>
                                    <img class="item-img" v-for="(v, k) in item.formItemValue.split(',')" :key="k"
                                        :src="v">
                                </template>
                                <template v-else-if="item.inputType == 8">
                                    <div style="display: flex;align-items: center;">
                                        <span>{{ item.formItemName }}:</span>
                                        <i @click.stop="linkTo(item.formItemValue)" class="el-icon-document"
                                            style="font-size:30px;margin-left:10px;cursor: pointer;"></i>
                                    </div>
                                </template>
                                <template v-else>
                                    <span>{{ item.formItemName }}：</span>
                                    <span>{{ item.formItemValue }}</span>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            <div style="text-align: center;">-</div>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column label="发起人部门" prop="applyUserDeptName"></el-table-column>
                <el-table-column label="工单名称" prop="sheetInstanceName"></el-table-column>
                <el-table-column label="发起时间">
                    <template slot-scope="scope">
                        <span>{{ changeDate(scope.row.applyTime, 1) || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="完成时间">
                    <template slot-scope="scope">
                        <span>{{ changeDate(scope.row.finishedTime, 1) || "-" }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="当前审批/办理人" prop="executeEmpNames" width="140"></el-table-column>
                <el-table-column label="审批状态" width="100">
                    <template slot-scope="scope">
                        <span :style="{ 'color': approveStatusColor[scope.row.approveStatus] }"
                            @click="onRow(scope.row)" class="pointer">{{
                                approveStatusObj[scope.row.approveStatus] }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <WorkSheetDialog :title="'查看详情'" :row="scope.row" v-has="'center_detail'" />
                        <el-button type="text" size="medium" @click.stop="onDel(scope.row)" v-has="'center_del'"
                            v-if="scope.row.approveStatus == 2">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-footer">
                <el-pagination :current-page.sync="currentPage" @current-change="onCurrentPage"
                    @size-change="onSizeChange" background layout="total,sizes, jumper, prev, pager, next"
                    :total="total" :page-sizes="[10, 50, 100, 500]" :page-size="params.limit">
                </el-pagination>
            </div>
        </div>
        <!-- 审批详情弹框 -->
        <el-drawer :title="drawerTitle" :visible.sync="drawerShow" size="40%">
            <section class="task-info">
                <!-- 头部信息 -->
                <div class="task-head">
                    <div class="head-title">
                        <img :src="taskInfo.applyUserHeadUrl ? taskInfo.applyUserHeadUrl : defaultHeadUrl">
                        <p>
                            <span>{{ taskInfo.applyUserName }}</span>
                            <span :style="{ 'color': approveStatusColor[taskInfo.approveStatus] }">{{
                                approveStatusObj[taskInfo.approveStatus] }}</span>
                        </p>
                    </div>
                    <template v-if="taskHead.approveFormSummaryValueVoList">
                        <div class="mid-item" v-for="item in taskHead.approveFormSummaryValueVoList"
                            :key="item.formItemName">
                            <span class="item-title" v-html="item.formItemName"></span>
                            <span class="item-text" v-if="item.formItemValue || item.fileList">
                                <template v-if="item.fileList">
                                    <div class="comment-img">
                                        <el-image class="comment-img-item"
                                            v-for="(it, f) in changeFileList(item.fileList).imgList" :key="f"
                                            :src="it.fileUrl"
                                            :preview-src-list="changeFileList(item.fileList).imgViewList">
                                        </el-image>
                                    </div>
                                    <div class="comment-a">
                                        <a class="comment-a-item" v-for="i in changeFileList(item.fileList).fileList"
                                            :key="i.fileId" :href="i.fileUrl" :download="i.fileName" target="_blank">
                                            {{ i.fileName }}
                                        </a>
                                    </div>
                                </template>
                                <template v-else>
                                    <span v-for="(i, index) in item.formItemValue" :key="index">
                                        <span v-html="i"></span>
                                    </span>
                                </template>
                            </span>
                            <span class="item-text" v-else>暂无</span>
                        </div>
                    </template>
                    <el-empty v-else description="暂无数据"></el-empty>
                </div>
                <!-- 附件信息 -->
                <div class="task-item" v-if="fileList && fileList.length > 0">
                    <p class="task-title">附件信息</p>
                    <div class="task-main">
                        <div class="comment-files">
                            <div class="comment-img">
                                <el-image class="comment-img-item" v-for="(it, f) in changeFileList(fileList).imgList"
                                    :src="it.fileUrl" :key="f" :preview-src-list="changeFileList(fileList).imgViewList">
                                </el-image>
                            </div>
                            <div class="comment-a">
                                <a class="comment-a-item" v-for="i in changeFileList(fileList).fileList" :key="i.fileId"
                                    :href="i.fileUrl" :download="i.fileName" target="_blank">
                                    {{ i.fileName }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 租赁价格列表 -->
                <div class="task-item" v-if="sellPriceList.length > 0">
                    <p class="task-title">租赁价格</p>
                    <div class="task-main">
                        <el-table :data="sellPriceList" style="width: 100%" border>
                            <el-table-column label="合同年" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.startDate || "-" }}</span>
                                    <span>~</span>
                                    <span>{{ scope.row.endDate || "-" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="免租日期" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <p v-for="(item, d) in scope.row.discountInfoDtoList" :key="d">
                                        {{ item.discountStartDate }}~{{ item.discountEndDate }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="优惠金额" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.discountAmount || "-" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="递增点" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <p v-for="(item, d) in scope.row.increaseInfoDtoList" :key="d">
                                        {{ item.increaseDate }}
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="单价递增" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <p v-for="(item, d) in scope.row.increaseInfoDtoList" :key="d">
                                        <span>
                                            {{ item.increaseRate }}
                                            <span v-if="item.increaseType == 1">%</span>
                                            <span v-if="item.increaseType == 2">元</span>
                                        </span>
                                    </p>
                                </template>
                            </el-table-column>
                            <el-table-column label="签约净价" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.signRealPrice }}</span>
                                    <span>{{ scope.row.pricingUnitName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="签约实价" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.signActualPrice }}</span>
                                    <span>{{ scope.row.pricingUnitName }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <!-- 相似房源列表 -->
                <div class="task-item" v-if="sameRoomList.length > 0">
                    <p class="task-title">相似房源</p>
                    <div class="task-main">
                        <el-table :data="sameRoomList" style="width: 100%" border>
                            <el-table-column label="签约房源" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.unitResource || "-" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="租赁面积" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.quantity || "-" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="基准价" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.baseAvgPrice }}</span>
                                    <span>{{ scope.row.pricingUnitName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="签约单价" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.signUnitPrice }}</span>
                                    <span>{{ scope.row.pricingUnitName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="签约净价" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.signRealPrice }}</span>
                                    <span>{{ scope.row.pricingUnitName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="签约日期" align="center" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.signToDate || "-" }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <!-- 审定单预览 -->
                <div class="task-item"
                    v-if="processType == 5 || processType == 12 || processType == 30 || processType == 31">
                    <p class="task-title">审定单</p>
                    <div class="task-main">
                        <div class="task-upload">
                            <el-image class="file-item" v-for="item in workflowAppList" :key="item.fileId"
                                :src="item.fileUrl" :preview-src-list="changeFileList(workflowAppList).imgViewList">
                            </el-image>
                            <span class="upload-box" @click=workflowAppUp() v-if="processType != 12"><i
                                    class="layui-icon layui-icon-addition"></i></span>
                        </div>
                    </div>
                </div>
                <!-- 验收单预览 -->
                <div class="task-item" v-if="processType == 12">
                    <p class="task-title">验收单</p>
                    <div class="task-main">
                        <div class="task-upload">
                            <el-image class="file-item" v-for="item in workflowCheckList" :key="item.fileId"
                                :src="item.fileUrl" :preview-src-list="changeFileList(workflowCheckList).imgViewList">
                            </el-image>
                            <span class="upload-box" @click=workflowCheckUp()><i
                                    class="layui-icon layui-icon-addition"></i></span>
                        </div>
                    </div>
                </div>
                <!-- 延期附件预览 -->
                <div class="task-item" v-if="processType == 32">
                    <p class="task-title">延期附件</p>
                    <div class="task-main">
                        <template v-if="workflowDelayList.length > 0">
                            <el-image class="file-item" v-for="item in workflowDelayList" :key="item.fileId"
                                :src="item.fileUrl" :preview-src-list="changeFileList(workflowDelayList).imgViewList">
                            </el-image>
                        </template>
                        <span v-else>暂无</span>
                    </div>
                </div>
                <!-- 电子合同下载 -->
                <div class="task-item" v-if="processType == 1">
                    <p class="task-title">电子合同</p>
                    <div class="task-main">
                        <template v-if="taskHead.projectFlag">
                            <el-button @click="downloads(11)"><i class="el-icon-download"></i>租赁合同</el-button>
                        </template>
                        <template v-if="!taskHead.projectFlag">
                            <el-button @click="downloads(1)" v-if="taskHead.contractType == 1"><i
                                    class="el-icon-download"></i>租赁合同</el-button>
                            <el-button @click="downloads(6)" v-if="taskHead.contractType == 2"><i
                                    class="el-icon-download"></i>续租合同</el-button>
                            <el-button @click="downloads(7)" v-if="taskHead.contractType == 3"><i
                                    class="el-icon-download"></i>滞留合同</el-button>
                        </template>
                        <el-button @click="downloads(2)"><i class="el-icon-download"></i>租赁账单</el-button>
                        <el-button @click="downloads(4)" v-if="taskHead.econtractType == 2"><i
                                class="el-icon-download"></i>物业合同</el-button>
                        <el-button @click="downloads(5)" v-if="taskHead.econtractType == 2"><i
                                class="el-icon-download"></i>物业账单</el-button>
                        <el-button @click="downloads(3)" v-if="accFileFlag"><i class="el-icon-download"></i>租赁合同附件
                        </el-button>
                        <el-button style="margin: 10px 0 0 0;" @click="downloads(8)" v-if="wuyeFileFlag"><i
                                class="el-icon-download"></i>物业合同附件
                        </el-button>
                    </div>
                </div>
                <!-- 电子合同下载-->
                <div class="task-item" v-if="processType == 80">
                    <p class="task-title">预定电子合同</p>
                    <div class="task-main">
                        <el-button @click="downloads(9)"><i class="el-icon-download"></i>预定合同</el-button>
                        <el-button @click="downloads(10)"><i class="el-icon-download"></i>预定账单</el-button>
                    </div>
                </div>
                <!-- 参数修改 -->
                <!-- <div class="task-item" v-if="this.ruleForm"> -->
                <div class="task-item" v-if="false">
                    <p class="task-title">{{ workSheetTypeName }}修改</p>
                    <div class="task-main">
                        <!-- 表单提交 -->
                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-myForm"
                            inline>
                            <section class="form-main" v-if="isIncludes('finalAmount')">
                                <el-form-item label="决算金额" prop="finalAmount">
                                    <el-input v-model="ruleForm.finalAmount"></el-input>
                                </el-form-item>
                            </section>
                            <section class="form-main" v-if="isIncludes('budgetAmount')">
                                <el-form-item label="预算金额" prop="budgetAmount">
                                    <el-input v-model="ruleForm.budgetAmount"></el-input>
                                </el-form-item>
                            </section>
                            <section class="form-main" v-if="isIncludes('formName')">
                                <el-form-item label="工单名称" prop="formName">
                                    <el-input v-model="ruleForm.formName"></el-input>
                                </el-form-item>
                            </section>
                        </el-form>
                    </div>
                </div>
                <!-- 关联工单跳转 -->
                <div class="task-item" v-if="taskHead.relateFormId">
                    <p class="task-title">关联工单</p>
                    <div class="task-main">
                        <el-button @click="onRelateForm">查看</el-button>
                    </div>
                </div>
                <!-- 合同详情跳转 -->
                <div class="task-item" v-if="processType == 1 || processType == 3">
                    <p class="task-title">合同详情</p>
                    <div class="task-main">
                        <el-button @click="onContractInfo">合同详情</el-button>
                        <el-button @click="onClueInfo" v-if="processType == 1">线索详情</el-button>
                    </div>
                </div>
                <!-- 电子合同跳转 -->
                <div class="task-item" v-if="processType == 80">
                    <p class="task-title">预定合同详情</p>
                    <div class="task-main">
                        <el-button @click="onReserveInfo">预定合同详情</el-button>
                        <el-button @click="onClueInfo">线索详情</el-button>
                    </div>
                </div>
                <!-- 项目详情跳转 -->
                <div class="task-item" v-if="processType == 90 && taskHead.projectInstanceId">
                    <p class="task-title">项目详情</p>
                    <div class="task-main">
                        <el-button
                            @click="window.open('/oa/workproject/workprojectInfo?projectInstanceId=' + taskHead.projectInstanceId)">
                            查看项目详情</el-button>
                    </div>
                </div>
                <!-- 项目附件下载 -->
                <div class="task-item" v-if="processType == 90 && approveStatus == 1">
                    <p class="task-title">项目附件下载</p>
                    <div class="task-main">
                        <el-button @click="onAllWorkProjectFile">审核附件整合下载</el-button>
                        <el-button @click="onWorkProjectFile">下载项目附件</el-button>
                    </div>
                </div>
                <!-- 付款账单 -->
                <div class="task-item" v-if="processType == 90 && taskHead.workSheetType == 20">
                    <p class="task-title">付款账单</p>
                    <div class="task-main">
                        <el-button @click="onWorkprojectBillInfo">查看账单明细</el-button>
                    </div>
                </div>
                <!-- 付款台账概况 -->
                <!-- <div class="task-item" v-if="processType == 90 && taskHead.workSheetType == 20">
                    <p class="task-title">付款台账概况</p>
                    <div class="task-main">
                        <el-button @click="onWorkprojectLedger">施工单位台账概况</el-button>
                        <el-button @click="onConstructionLedger">园区台账概况</el-button>
                    </div>
                </div> -->
                <!-- 结佣相关信息 -->
                <div class="task-item" v-if="processType == 103">
                    <p class="task-title">结佣相关信息</p>
                    <div class="task-main">
                        <el-button @click="onAccountInfo">结佣详情</el-button>
                        <el-button @click="onContractInfo">合同详情</el-button>
                        <el-button @click="onClueInfo">线索详情</el-button>
                    </div>
                </div>
                <!-- 结佣附件下载 -->
                <div class="task-item" v-if="processType == 103 && approveStatus == 1">
                    <p class="task-title">结佣附件下载</p>
                    <div class="task-main">
                        <el-button @click="onAccountFiles('结佣附件下载')">结佣附件下载</el-button>
                    </div>
                </div>
                <!-- 付佣相关信息 -->
                <div class="task-item" v-if="processType == 104">
                    <p class="task-title">付佣相关信息</p>
                    <div class="task-main">
                        <el-button @click="onAccountInfo">结佣详情</el-button>
                        <el-button @click="onContractInfo">合同详情</el-button>
                        <el-button @click="onClueInfo">线索详情</el-button>
                        <el-button @click="onPartnerInfo">合伙人详情</el-button>
                        <el-button @click="onApproveInfo">相关结佣审批</el-button>
                    </div>
                </div>
                <!-- 付佣附件下载 -->
                <div class="task-item" v-if="processType == 104 && approveStatus == 1">
                    <p class="task-title">付佣附件下载</p>
                    <div class="task-main">
                        <el-button @click="onAccountFiles('付佣附件下载')">付佣附件下载</el-button>
                    </div>
                </div>
                <!-- 审批评论 -->
                <div class="task-item">
                    <p class="task-title">审批评论</p>
                    <div class="task-main">
                        <ul class="task-list">
                            <li class="list-item" v-for="(item, index) in taskCommentList" :key="index">
                                <div class="list-img">
                                    <img v-if="item.executionId" class="imgs" :src="changeHeadImg(item)" />
                                    <img v-else class="imgs" :src="defaultHeadUrl" />
                                    <div :class="{ line: index != taskCommentList.length - 1 }"></div>
                                </div>
                                <div class="list-status" v-if="item.executionId">
                                    <p>
                                        <span>
                                            {{ item.nodeName }}
                                            <span
                                                v-html="changeComment(item.executionStatus, item.executionAction, item.nodeType)"></span>
                                        </span>
                                        <span v-if="item.nodeType == 3 && item.executionTime">{{
                                            changeDate(item.executionStartTime, 1) }}
                                            至 {{ changeDate(item.executionTime, 1) }}</span>
                                        <span v-else>{{ changeDate(item.executionTime, 1) }}</span>
                                    </p>
                                    <div>
                                        <div>
                                            <span
                                                v-if="item.assigneeType == 3 && item.executionStatus != 2 && item.executionStatus != 3">
                                                {{ item.roleName }}
                                            </span>
                                            <span v-else>
                                                {{ item.operatorName }}
                                            </span>
                                            <span v-if="item.assigneeEventActionName">({{ item.assigneeEventActionName
                                                }})</span>
                                        </div>
                                        <ul class="to-transact"
                                            v-if="item.dealActionVo && item.dealActionVo.params && item.dealActionVo.params.length > 0">
                                            <li class="to-transact-item" v-for="(it, dav) in item.dealActionVo.params"
                                                :key="dav">
                                                <b>{{ it.key }}：</b>
                                                <span>{{ it.value }}</span>
                                            </li>
                                        </ul>
                                        <ul class="to-transact" v-if="item.dealActionVo && item.dealActionVo.linkList">
                                            <div v-for="(i, j) in item.dealActionVo.linkList" :key="j">
                                                <template v-if="[105, 106, 112].includes(i.assigneeEventAction)">
                                                    <el-button v-if="i.signType == 1" type="primary" size="mini" plain
                                                        @click="onLink(i.linkUrl)">
                                                        {{ i.urlName }}</el-button>
                                                    <a v-if="i.signType == 2" :href="i.linkUrl" :download="i.urlName"
                                                        target="_blank" style="display: inline-block;margin: 0 15px;">
                                                        <i class="el-icon-download"></i>{{ i.urlName }}
                                                    </a>
                                                </template>
                                            </div>
                                        </ul>
                                        <ul class="to-transact"
                                            v-if="(item.executionStatus == 1 || item.executionStatus == 2) && item.assigneeEventAction == 107">
                                            <el-button type="primary" size="mini" plain
                                                @click="onPatrolLog(item.approveId)">巡查日志</el-button>
                                        </ul>
                                        <ul class="to-transact"
                                            v-if="(item.assigneeEventAction == 103 || item.assigneeEventAction == 104 || item.assigneeEventAction == 109 || item.assigneeEventAction == 111 || item.assigneeEventAction == 117) && item.executionStatus == 2">
                                            <el-button type="primary" size="mini" plain
                                                v-if="item.assigneeEventAction == 103 || item.assigneeEventAction == 104"
                                                @click="onWorkProjectBill(item.approveId, item.assigneeEventAction)">
                                                查看预算单</el-button>
                                            <el-button type="primary" size="mini" plain
                                                v-if="item.assigneeEventAction == 109"
                                                @click="onWorkProjectBill(item.approveId, item.assigneeEventAction)">
                                                查看决算单</el-button>
                                            <el-button type="primary" size="mini" plain
                                                v-if="item.assigneeEventAction == 111"
                                                @click="onWorkProjectBill(item.approveId, item.assigneeEventAction)">
                                                查看审定单</el-button>
                                            <el-button type="primary" size="mini" plain
                                                v-if="item.assigneeEventAction == 117"
                                                @click="onWorkProjectBill(item.approveId, item.assigneeEventAction)">
                                                查看二次审定单</el-button>
                                            <el-button type="primary" size="mini" plain
                                                v-if="item.assigneeEventAction == 104"
                                                @click="onWorkProjectScoring(item.approveId, item.assigneeEventAction)">
                                                查看设计评分表</el-button>
                                        </ul>
                                        <ul class="to-transact"
                                            v-if="item.executionStatus == 2 && item.assigneeEventAction == 403">
                                            <el-button type="primary" size="mini" plain
                                                @click="onWorkProjectCheck(item.approveId, item.assigneeEventAction)">
                                                查看验收确认单</el-button>
                                        </ul>
                                        <template
                                            v-if="item.dealActionVo && item.dealActionVo.fileList && item.dealActionVo.fileList.length > 0">
                                            <div class="comment-files">
                                                <div class="comment-img">
                                                    <el-image class="comment-img-item"
                                                        v-for="(it, index) in changeFileList(item.dealActionVo.fileList).imgList"
                                                        :src="it.fileUrl" :key="index"
                                                        :preview-src-list="changeFileList(item.dealActionVo.fileList).imgViewList">
                                                    </el-image>
                                                </div>
                                                <div class="comment-a">
                                                    <a class="comment-a-item"
                                                        v-for="i in changeFileList(item.dealActionVo.fileList).fileList"
                                                        :key="i.fileId" :href="i.fileUrl" :download="i.fileName"
                                                        target="_blank">
                                                        {{ i.fileName }}
                                                    </a>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                    <p style="font-size: 12px; color: #999"
                                        v-for="(i, index) in item.approveExecutionCommentVoList" :key="index">
                                        <span>{{ i.candidateUserName }}评论：{{ i.comments }}</span>
                                    </p>
                                </div>
                                <div class="list-status" v-else>
                                    <p>
                                        <span>
                                            {{ item.userName }}
                                            添加了评论
                                        </span>
                                        <span>{{ changeDate(item.executionTime, 1) }}</span>
                                    </p>
                                    <p class="com-recall">
                                        <span class="com-text">
                                            {{ item.content }}
                                        </span>
                                        <span class="com-back" v-if="item.userId == userInfo.userId"
                                            @click="onDelComment(item.commentId)">撤回</span>
                                    </p>
                                    <div class="comment-files"
                                        v-if="item.imgList && item.imgList.length > 0 || item.fileList && item.fileList.length > 0">
                                        <div class="comment-img">
                                            <el-image class="comment-img-item" v-for="(it, img) in item.imgList"
                                                :src="it.fileUrl" :preview-src-list="changeImgs(item.imgList)"
                                                :key="img">
                                            </el-image>
                                        </div>
                                        <div class="comment-a">
                                            <a class="comment-a-item" v-for="i in item.fileList" :key="i.attId"
                                                :href="i.fileUrl" :download="i.fileName" target="_blank">
                                                {{ i.fileName }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <el-row class="approve-btn" v-if="taskInfo.approveStatus == 0 || taskInfo.approveStatus == 3">
                            <el-button type="warning" plain v-if="taskInfo.myExecutionTask" @click="onTransfer()">转交
                            </el-button>
                            <el-button type="primary" plain v-if="taskInfo.myApproveTask" @click="onRevocation()">撤回
                            </el-button>
                            <el-button type="danger"
                                v-if="taskInfo.myExecutionTask && taskInfo.approveStatus == 3 && rejectEnable"
                                @click="onRefuse()">拒绝
                            </el-button>
                            <el-button type="danger" v-if="taskInfo.myExecutionTask && taskInfo.approveStatus != 3"
                                @click="onRefuse()">拒绝
                            </el-button>
                            <el-button type="primary" v-if="taskInfo.myExecutionTask && taskInfo.approveStatus == 0"
                                @click="onAgree('ruleForm')">通过
                            </el-button>
                            <el-button type="success" v-if="taskInfo.myExecutionTask && taskInfo.approveStatus == 3"
                                @click="onToTransact">去办理
                            </el-button>
                            <el-button type="primary" v-if="taskInfo.myExecutionTask && taskInfo.approveStatus == 3"
                                @click="onSubmit('ruleForm')">办理完成
                            </el-button>
                            <el-button type="danger" plain v-if="taskInfo.myExecutionTask && taskInfo.processType == 90"
                                @click="onReBack">回退
                            </el-button>
                        </el-row>
                    </div>
                </div>
                <!-- 发表评论 -->
                <div class="task-item" v-if="commentShow">
                    <p class="task-title">添加评论</p>
                    <div class="task-main">
                        <!-- 添加评论 -->
                        <li class="comment">
                            <div class="com-left">
                                <el-avatar :size="40" :src="userInfo.headPicUrl || defaultHeadUrl"></el-avatar>
                            </div>
                            <div class="com-right">
                                <!-- 上传文件 -->
                                <div class="com-add">
                                    <em class="com-em">我</em>
                                    <span class="com-upload">
                                        <img @click="atShow = true" class="com-icon"
                                            :src="require('@/assets/images/examine/at.png')" />
                                        <img class="com-icon" @click="onUpload"
                                            :src="require('@/assets/images/examine/upload.png')" />

                                    </span>
                                </div>
                                <!-- 输入内容 -->
                                <div class="con-flex">
                                    <div class="con-inp">
                                        <el-input type="textarea" class="con-inp-van" rows="5"
                                            :autosize="{ minRows: 6 }" placeholder="请输入评论内容" v-model="content"
                                            maxlength="200" show-word-limit>
                                        </el-input>

                                    </div>
                                    <el-button class="con-sent" @click="onAddComment" :disabled="!content">发送
                                    </el-button>
                                </div>
                                <!-- 通知列表 -->
                                <div class="con-at" v-if="userIds.length > 0" @click="atShow = true">
                                    <i class="el-icon-error del-attid" @click="onUserIds"></i>
                                    <span class="at-user" v-for="item in userIds" :key="item.candidateUserId">@{{
                                        item.candidateUserName }}</span>
                                </div>
                                <!-- 文件预览 -->
                                <div class="con-file" v-if="attIds.length > 0">
                                    <i class="el-icon-error del-attid" @click="onAttIds"></i>
                                    <template v-if="comImgList.length > 0">
                                        <el-image class="file-item" v-for="(item, index) in comImgList" :src="item.url"
                                            :preview-src-list="changeImgs(comImgList)" :key="index">
                                        </el-image>
                                    </template>
                                    <template v-if="comFileList.length > 0">
                                        <a v-for="(item, index) in comFileList" :href="item.url"
                                            :download="item.fileName" :key="index">
                                            <img class="file-item" :src="require('@/assets/images/examine/files.png')"
                                                alt="">
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </li>
                    </div>
                </div>
                <!-- 已经到底 -->
                <div class="task-foot">
                    <span>已经到底了</span>
                </div>
            </section>
        </el-drawer>
        <!-- 转交列表弹窗 -->
        <el-dialog :title="dialogTitle" :visible.sync="dialogShow" width="20%" top="40vh">
            <el-select v-model="transferUserId" filterable placeholder="请选择">
                <el-option v-for="item in userList" :key="item.candidateUserId" :label="item.candidateUserName"
                    :value="item.candidateUserId">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onDialogSave()">确 认</el-button>
            </span>
        </el-dialog>
        <!-- 通知列表弹窗 -->
        <el-drawer title="通知列表" :visible.sync="atShow" direction="rtl" size="20%">
            <div class="at-search">
                <el-input placeholder="请搜索用户" v-model="atKw" clearable>
                    <i slot="prefix" class="el-input__icon el-icon-search"></i>
                </el-input>
            </div>
            <div class="at-users">
                <el-checkbox-group v-model="userIds">
                    <div class="cell" v-for="(item, index) in changeUserList(atKw)" :key="index">
                        <el-checkbox :label="item">{{ item.candidateUserName }}</el-checkbox>
                    </div>

                </el-checkbox-group>
            </div>
        </el-drawer>
        <UpLoad @fileIds="getFileIds" ref="up" :fileType="3" :maxlimit="5"></UpLoad>
    </div>
</template>

<script>
import { ObjToOpt, changeDate, changeHttps, changeFileList } from "@/utils/utils";
import { processTypeObj, approveStatusObj, approveStatusColor } from "@/db/objs";
import { queryDept } from "@/api/organization/department.js";
import centerApi from "@/api/examine/center.js";
import WorkSheetDialog from "./child/workSheetDialog.vue";
import { getFileList } from "@/api/common";
import { formatterDate } from "@/utils/utils";
import {
    getCardOrderExport,
} from "@/api/card/order";
import UpLoad from "@/components/upload";
export default {
    components: {
        WorkSheetDialog, UpLoad
    },
    data() {
        return {
            formatterDate, ObjToOpt, changeDate, changeHttps, changeFileList, processTypeObj, approveStatusObj, approveStatusColor,
            headerCellStyle: {
                background: '#FAFAFA',
                color: '#000000',
            },
            defaultHeadUrl: require('@/assets/images/examine/default2x.png'),
            rules: {
                finalAmount: [{
                    required: true,
                    message: '请输入决算金额',
                    trigger: 'blur'
                }],
                budgetAmount: [{
                    required: true,
                    message: '请填写预算金额',
                    trigger: 'blur'
                }], formName: [{
                    required: true,
                    message: '请输入工单名称',
                    trigger: 'blur'
                }],
            },
            props: {
                value: 'deptId',
                label: 'deptName',
                children: 'childDeptVoList',
                multiple: true,
                checkStrictly: true,
                emitPath: false
            },
            startDateToDate: [],//申请开始时间范围
            doneDateToDate: [],//完成日期范围
            workSheetTypeList: [],//工单类型
            departmentList: [],//部门列表
            drawerTitle: "审批详情", //抽屉标题
            drawerShow: false, //审批弹框开关
            approveId: "", //审批ID
            processType: "", //审批类型
            approveStatus: "", //审批状态
            //用户信息 
            userInfo: "",
            // 列表参数
            params: {
                page: 1,
                limit: 10,
                kw: "",
                taskType: "1", //豆腐块
                timeType: "0", //月，年，全部
                processType: "", //审批类型
                approveStatus: "", //审批状态
                applyTimeFrom: "", //申请开始时间
                applyTimeTo: "", //申请结束时间
            },
            // 列表详情
            tableData: [], //表格数据
            currentPage: 1, // 当前页
            total: 0, // 总条数  
            // 列表豆腐块
            tofuOpt: [{
                value: "1",
                label: "待我处理",
                name: "myExecutionCnt",
                count: "--"
            },
            {
                value: "2",
                label: "我已处理",
                name: "myApproveCnt",
                count: "--"
            },
            {
                value: "3",
                label: "我已发起",
                name: "myStartCnt",
                count: "--"
            },
            {
                value: "4",
                label: "我已收到",
                name: "myCcCnt",
                count: "--"
            },
            {
                value: "5",
                label: "部门任务",
                name: "deptCnt",
                count: "--"
            }],
            // 审批详情
            userList: [], //用户列表
            taskHead: "", //审批头部详情
            taskInfo: [], //审批进度列表
            commentsId: "", //发起人ID
            agreenId: "", //审批人ID
            assigneeEventAction: "", //办理节点ID
            nodeType: "", //办理节点状态
            rejectEnable: false, //办理节点拒绝显示开关
            transferUserId: "", //转交人ID
            // 审批评论
            taskCommentList: [], //审批评论列表
            commentShow: true, //评论展示开关
            content: "", //评论内容
            userIds: [], //评论通知人员
            atShow: false, //通知列表开关
            atKw: "", //搜索客户
            attIds: [], //评论附件信息
            comFileList: [], //附件信息
            comImgList: [], //图片信息
            // 附件信息
            fileList: [], //附件
            workflowAppList: [], //审定单
            workflowCheckList: [], //验收单
            workflowDelayList: [], //延期附件
            // 合同签约
            accFileFlag: false, //合同附件
            wuyeFileFlag: false, //合同附件
            // 协同修改
            ruleForm: {},
            sellPriceList: [], //租赁价格
            sameRoomList: [], //相似房源
            // 线索相关
            dialogShow: false, //转交列表弹框开关
            dialogTitle: "", //转交列表弹框标题
            clueId: "", //线索ID
            workSheetTypeName: "", //工单类型
        }
    },
    created() {
        this.initData();

        if (this.approveId) {
            let row = {
                approveId: this.approveId,
                processType: this.processType,
                clueId: ""
            };
            this.onRow(row)
        }
    },
    methods: {

        initData() {
            this.setDefaultDateRange();
            this.getUserInfo(); // 【请求】用户信息
            this.queryDeptList();
            this.getWorkSheetTypes();
        },
        setDefaultDateRange() {
            const now = new Date();
            const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
            const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            this.params.applyFormTime = this.formatterDate(startOfMonth);
            this.params.applyToTime = this.formatterDate(endOfMonth);
            this.params.applyTimeFrom = startOfMonth.getTime();
            this.params.applyTimeTo = endOfMonth.getTime() + 86400000-1;
            this.startDateToDate = [startOfMonth, endOfMonth];
            this.getTopCounts(); // 【请求】豆腐块数据
            this.getTableList(); // 【请求】表格数据
        },
        // 【请求】工单类型下拉列表
        getWorkSheetTypes() {
            let data = {};
            centerApi.projectType(data).then(res => {
                if (res.isSuccess == 'yes') {
                    this.workSheetTypeList = res.data;
                }
            })

        },
        onExport() {
            centerApi.exportList(this.params)
        },
        onAdd() {
            this.workOrderEditShow = true;
        },
        // 查询部门列表
        queryDeptList() {
            const params = {
                deptStatus: ''
            };
            queryDept(params).then(res => {
                this.departmentList = [res.data];
            });
        },
        // 【监听】表格行点击
        onRow(row) {
            this.approveId = row.approveId;
            this.processType = row.processType;
            this.workSheetTypeName = row.workSheetTypeName;
            this.clueId = row.clueId;
            this.approveDrawerTitle(); //【转换】审批弹窗标题
            this.drawerShow = true;
            this.taskHead = ""; //清空上次头部信息
            this.taskInfo = []; //清空上次审批流程
            this.taskCommentList = []; //清空上次审批流程
            this.fileList = []; //清空上次附件信息
            this.workflowAppList = []; //清空上次审定单
            this.workflowCheckList = []; //清空上次验收单
            this.workflowDelayList = []; //清空上次延期附件
            this.accFileFlag = false; //清空上次合同附件开关
            this.wuyeFileFlag = false; //清空上次合同附件开关
            this.content = ""; //清空上次评论内容
            this.userIds = []; //清空上次通知对象
            this.attIds = ""; //清空上次评论附件信息
            this.sellPriceList = []; //清空售卖价格
            this.sameRoomList = []; //清空相似房源
            this.getUserList(); // 【请求】转交用户列表
            // 只需approveId
            this.getTopInfo(); //通过申请id 查询审批申请明细
            this.getTaskInfo(); //【请求】节点信息
            this.getApproveInfo(); //【请求】流程和评论信息
            this.CommenUpdate(); //评论】更新已读
            // 需要processType
            this.getFileList(); //【请求】附件信息
            // this.getNodeParam(); //获取修改项
            // this.getContractRentPrice(); //【请求】租赁价格表格
            // this.getContractLikeResource(); //【请求】租赁价格表格
        },
        onDel(row) {
            this.$confirm('是否删除该工单?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    sheetInstanceId: row.sheetInstanceId
                };
                centerApi.deleteWorkSheet(data).then(res => {
                    if (res.isSuccess == 'yes') {
                        this.$message.success("删除成功");
                        this.onTableReload();
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            })

        },

        // 【转换】审批弹窗标题
        approveDrawerTitle() {
            let name = "";
            if (this.taskInfo.applyUserName) {
                name = this.taskInfo.applyUserName + "提交的" + this.workSheetTypeName;
            }
            this.drawerTitle = name;
        },

        // 【转换】头像信息
        changeHeadImg(item) {
            let src;
            if (item.approveExecutionCandidateDtoList && item.approveExecutionCandidateDtoList[0] && item.approveExecutionCandidateDtoList[0].candidateUserHeadUrl != "") {
                return src = item.approveExecutionCandidateDtoList[0].candidateUserHeadUrl
            } else {
                return src = this.defaultHeadUrl
            }
        },

        // 【转换】图片预览
        changeImgs(list) {
            let arr = [];
            if (list) {
                for (let i of list) {
                    arr = [...arr, i.fileUrl]
                }
            }
            return arr
        },

        // 【转换】跳转路径
        linkTo(url) {
            window.location.href = url;
        },

        // 【转换】表格标题
        changeTitle(row) {
            if (row.processName.substring((row.processName.length - 2), row.processName.length) == "申请") {
                return `<span style="color:#1E9FFF">${row.applyUserName}</span>提交的<span style="color:#009688">${row.processName}</span>`
            } else {
                return `<span style="color:#1E9FFF">${row.applyUserName}</span>提交的<span style="color:#009688">${row.processName}申请</span>`
            }
        },

        // 【转换】执行状态
        // 【转换】执行状态
        changeComment(executionStatus, executionAction, nodeType) {
            let text;
            if (nodeType == 6) {
                text = ''
            } else if (executionStatus == 0 && executionAction == null && nodeType == 4) {
                text = "(待抄送)";
            } else if (executionStatus == 2 && executionAction == 1 && nodeType == 4) {
                text = "(已抄送)";
            } else if (executionStatus == 0 && executionAction == null && nodeType != 3) {
                text = '<span style="color:#909399">(待审批)</span>';
            } else if (executionStatus == 1 && executionAction == null && nodeType != 3) {
                text = `<span style="color:#E6A23C">(审批中)</span>`;
            } else if (executionStatus == 0 && executionAction == null && nodeType == 3) {
                text = '<span style="color:#909399">(待办理)</span>';
            } else if (executionStatus == 1 && executionAction == null && nodeType == 3) {
                text = `<span style="color:#E6A23C">(办理中)</span>`;
            } else if (executionStatus == 2 && executionAction == 0) {
                text = '<span style="color:#009688">(已提交)</span>';
            } else if (executionStatus == 2 && executionAction == 1) {
                text = '<span style="color:#409EFF">(已通过)</span>';
            } else if (executionStatus == 2 && executionAction == 2) {
                text = '<span style="color:#FF5722">(已拒绝)</span>';
            } else if (executionStatus == 2 && executionAction == 3) {
                text = "(已转交)";
            } else if (executionStatus == 2 && executionAction == 4) {
                text = "(已退回)";
            } else if (executionStatus == 2 && executionAction == 5) {
                text = '<span style="color:#909399">(已撤回)</span>';
            } else if (executionStatus == 3) {
                text = '(已回退)';
            }

            return text;
        },


        // 【转换】判断是否包含该项
        isIncludes(str) {
            return JSON.stringify(this.ruleForm).includes(str)
        },

        // 【转换】用户列表
        changeUserList(atKw) {
            let list = [];
            // 过滤掉自己
            const arr = this.userList.filter((i) => {
                return i.candidateUserId != this.userInfo.userId
            })
            arr.forEach((i) => {
                if (JSON.stringify(i).search(atKw) != -1) {
                    list.push(i);
                }
            });
            return list;
        },


        // 【转换】通知对象
        changeUserIds(userIds) {
            let arr = [];
            for (let i of userIds) {
                arr = [...arr, i.candidateUserId];
            }
            return arr;
        },

        // 【请求】用户信息
        getUserInfo() {
            centerApi.userInfo().then(res => {
                if (res.isSuccess = 'yes') {
                    this.userInfo = res.data;
                }
            })
        },

        // 【请求】表格数据
        getTableList() {
            let data = this.params;
            centerApi.tableData(data).then(res => {
                if (res.isSuccess == 'yes') {
                    this.total = res.data.total;
                    this.tableData = res.data.list;
                }
            })

        },

        // 【请求】豆腐块数据
        getTopCounts() {
            let data = this.params;
            centerApi.tofuCount(data).then(res => {
                if (res.isSuccess = 'yes') {
                    for (let i of this.tofuOpt) {
                        i.count = res.data[i.name]
                    }
                }
            })
        },

        // 【请求】用户列表
        getUserList() {
            let data = {};
            centerApi.examineList(data).then(res => {
                if (res.isSuccess = 'yes') {
                    this.userList = res.data;
                }
            })
        },
        startDateChange(value) {
            if (value) {
                this.params.applyFormTime = this.startDateToDate[0];
                this.params.applyToTime = this.startDateToDate[1];
                this.params.applyTimeFrom = new Date(this.startDateToDate[0]).getTime();
                this.params.applyTimeTo = new Date(this.startDateToDate[1]).getTime() + 86400000-1;
            } else {
                this.params.applyFormTime = '';
                this.params.applyToTime = '';
                this.params.applyTimeFrom = '';
                this.params.applyTimeTo = '';
            }
            this.onTableReload();
        },
        doneDateChange(value) {
            if (value) {
                this.params.finishFormTime = this.doneDateToDate[0];
                this.params.finishToTime = this.doneDateToDate[1];
            } else {
                this.params.finishFormTime = '';
                this.params.finishToTime = '';
            }
            this.onTableReload();
        },

        //【请求】表格豆腐块重载
        onTableReload() {
            this.getTopCounts(); // 【请求】豆腐块数据
            this.params.page = 1;
            this.currentPage = 1;
            this.getTableList(); // 【请求】表格数据
        },
        kwChange: _.debounce(function () {
            this.onTableReload();
        }),
        // 【监听】豆腐块选择
        onTofu(value) {
            this.params.taskType = value;
            this.onTableReload();
        },
        // 【监听】表格分页点击
        onCurrentPage(page) {
            this.params.page = page;
            this.getTableList(); // 【请求】表格数据
        },

        // 【监听】表格条数
        onSizeChange(limit) {
            this.params.limit = limit;
            this.onTableReload();
        },

        // 【请求】头部信息
        getTopInfo() {
            let data = {
                approveId: this.approveId,
            };
            centerApi.topInfo(data).then(res => {
                if (res.isSuccess = 'yes') {
                    this.taskHead = res.data;
                    // 合同签约
                    if (this.processType == 1) {
                        this.getAccFile(); // 【请求】查询是否有附件
                        this.getWuyeFile(); // 【请求】查询是否有附件
                    };

                }
            })
        },

        // 【请求】节点信息
        getTaskInfo() {
            let data = {
                approveId: this.approveId,
            };
            centerApi.queryProcessDetail(data).then(res => {
                if (res.isSuccess = 'yes') {
                    this.taskInfo = res.data;
                    this.approveStatus = res.data.approveStatus;//审批状态
                    let list = res.data.executionDtoList;
                    if (list && list.length > 0) {
                        this.commentsId = list[0].executionId; //获取发起人ID
                        this.agreenId = list.filter(i => i.executionStatus == 1)[0]?.executionId; //获取当前审批人ID
                        this.assigneeEventAction = list.filter(i => i.executionStatus == 1)[0]?.assigneeEventAction; //获取当前办理ID
                        this.nodeType = list.filter(i => i.executionStatus == 1)[0]?.nodeType; //获取当前办理ID
                        this.rejectEnable = list.filter(i => i.executionStatus == 1)[0]?.rejectEnable || false; //获取当前办理节点展示
                    }

                }
            })
        },

        // 【请求】附件信息
        getFileList() {
            let data = {
                approveId: this.approveId,
                processType: this.processType,
            };
            centerApi.queryAttachments(data).then(res => {
                if (res.isSuccess = 'yes') {
                    changeHttps(res.data);
                    this.fileList = res.data.filter(i => i.fileType == 1);
                    this.workflowAppList = res.data.filter(i => i.fileType == 2); //审定单
                    this.workflowCheckList = res.data.filter(i => i.fileType == 3); //验收单
                    this.workflowDelayList = res.data.filter(i => i.fileType == 4); //延期附件
                }
            })
        },

        // 【请求】流程和评论信息
        getApproveInfo() {
            let data = {
                approveId: this.approveId,
            };
            centerApi.queryProcessAndComment(data).then(res => {
                if (res.isSuccess = 'yes') {
                    this.taskCommentList = res.data;
                }
            })
        },

        // 【请求】租赁价格表格
        getContractRentPrice() {
            let data = {
                approveId: this.approveId,
                processType: this.processType,
            };
            centerApi.queryLeasePrice(data).then(res => {
                if (res.isSuccess = 'yes') {
                    this.sellPriceList = res.data;
                }
            })
        },

        // 【请求】相似房源表格
        getContractLikeResource() {
            let data = {
                approveId: this.approveId,
                processType: this.processType,
            };
            centerApi.querySimilarHouse(data).then(res => {
                if (res.isSuccess = 'yes') {
                    this.sameRoomList = res.data;
                }
            })
        },

        // 【审批流程】撤回
        onRevocation() {
            this.$prompt('请输入撤回原因（选填）', "撤回", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({
                value
            }) => {
                let data = {
                    executionId: this.commentsId, //发起人ID
                    comments: value, //
                };
                centerApi.recall(data).then(res => {
                    if (res.isSuccess == 'yes') {
                        this.$message.success(res.message);
                        this.getTaskInfo(); //【请求】节点信息
                        this.getApproveInfo(); //【请求】流程和评论信息
                        this.onTableReload(); // 【请求】表格豆腐块重载
                    }
                })

            })
        },

        // 【审批流程】拒绝
        onRefuse() {
            this.$prompt('请输入拒绝原因（选填）', "拒绝", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({
                value
            }) => {
                let data = {
                    executionId: this.agreenId, //当前审核人id
                    comments: value, //拒绝原因
                };
                centerApi.reject(data).then(res => {
                    if (res.isSuccess == 'yes') {
                        this.$message.success(res.message);
                        this.getTaskInfo(); //【请求】节点信息
                        this.getApproveInfo(); //【请求】流程和评论信息
                        this.onTableReload(); // 【请求】表格豆腐块重载
                    }
                })

            })
        },

        // 【审批流程】通过
        onAgree(formName) {
            this.$prompt('请输入通过原因（选填）', "通过", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(({
                value
            }) => {
                let data = {
                    executionId: this.agreenId, //当前审核人id
                    approveId: this.approveId,
                    processType: this.processType,
                    comments: value, //拒绝原因
                };
                centerApi.pass(data).then(res => {
                    if (res.isSuccess == 'yes') {
                        this.$message.success(res.message);
                        this.getTopInfo(); //【请求】审批头部信息
                        this.getTaskInfo(); //【请求】节点信息
                        this.getApproveInfo(); //【请求】流程和评论信息
                        this.onTableReload(); // 【请求】表格豆腐块重载
                        // this.getNodeParam(); // 【工单修改】获取审批修改项
                    }
                })
            })
        },

        // 【审批流程】通过
        onSubmit(formName) {
            if (this.ruleForm) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$prompt('请输入办理完成原因（选填）', "办理完成", {
                            confirmButtonText: '确定',
                            cancelButtonText: '取消',
                        }).then(({
                            value
                        }) => {
                            let data = {
                                executionId: this.agreenId, //当前审核人id
                                approveId: this.approveId,
                                processType: this.processType,
                                comments: value, //拒绝原因
                                nodeParams: this.ruleForm,
                            };
                            centerApi.submit(data).then(res => {
                                if (res.isSuccess == 'yes') {
                                    this.$message.success(res.message);
                                    this.getTopInfo(); //【请求】审批头部信息
                                    this.getTaskInfo(); //【请求】节点信息
                                    this.getApproveInfo(); //【请求】流程和评论信息
                                    this.onTableReload(); // 【请求】表格豆腐块重载
                                    // this.getNodeParam(); // 【工单修改】获取审批修改项
                                }
                            })
                        })
                    } else {
                        return false;
                    }
                });
            } else {
                this.$prompt('请输入办理完成原因（选填）', "办理完成", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                }).then(({
                    value
                }) => {
                    let data = {
                        executionId: this.agreenId, //当前审核人id
                        approveId: this.approveId,
                        processType: this.processType,
                        comments: value, //拒绝原因
                    };
                    centerApi.submit(data).then(res => {
                        if (res.isSuccess == 'yes') {
                            this.$message.success(res.message);
                            this.getTopInfo(); //【请求】审批头部信息
                            this.getTaskInfo(); //【请求】节点信息
                            this.getApproveInfo(); //【请求】流程和评论信息
                            this.onTableReload(); // 【请求】表格豆腐块重载
                            // this.getNodeParam(); // 【工单修改】获取审批修改项
                        }
                    })
                })
            }
        },

        // 【工程相关审批】去办理
        onToTransact() {
            let obj = toTransactOpt[this.assigneeEventAction];
            layer.open({
                type: 2,
                title: [obj.title, 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                maxmin: obj.maxmin,
                area: [obj.width, obj.height],
                content: obj.url + '?assigneeEventAction=' + this.assigneeEventAction + "&processType=" + this.processType + "&approveId=" + this.approveId + "&agreenId=" + this.agreenId,
                end: () => {
                    this.getTopInfo(); //【请求】审批头部信息
                    this.getTaskInfo(); //【请求】节点信息
                    this.getApproveInfo(); //【请求】流程和评论信息
                },
            });
        },

        // 【工程相关审批】回退
        onReBack() {
            layer.confirm('是否要回退?', {
                title: "操作",
                btn: ['确认', '取消']
            }, (index) => {
                this.onGetBack(index)
            });
        },

        // 【请求】回退
        onGetBack: _.throttle(function (index) {
            let data = {
                approveId: this.approveId,
                processType: this.processType,
                executionId: this.agreenId, //当前审核人id
            };
            centerApi.rollback(data).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$message.success(res.message);
                    layer.close(index);
                    this.getTaskInfo(); //【请求】节点信息
                    this.getApproveInfo(); //【请求】流程和评论信息
                }
            })

        }, 3000, {
            trailing: false
        }),

        //【审批流程】用户弹窗
        onTransfer() {
            this.transferUserId = "";
            this.dialogTitle = "转交列表";
            this.dialogShow = true;
        },

        // 【审批流程】弹框保存
        onDialogSave() {
            if (this.dialogTitle == "转交列表") {
                this.getTransfer();
            }
        },

        // 【审批流程】转交
        getTransfer() {
            let data = {
                executionId: this.agreenId, //当前审核人id
                transferUserId: this.transferUserId, //转交人id
            };
            if (!this.transferUserId) return this.$message.info('请选择转交人员')
            centerApi.transfer(data).then(res => {
                if (res.isSuccess == 'yes') {
                    this.$message.success(res.message);
                    this.getTaskInfo(); //【请求】节点信息
                    this.getApproveInfo(); //【请求】流程和评论信息
                    this.onTableReload(); // 【请求】表格豆腐块重载
                    this.dialogShow = false;
                }
            })
        },
        // 【合同签约】合同下载
        downloads(num) {
            let type = null;
            if (this.taskHead.econtractType == 1) {
                type = 1
            } else {
                type = 3
            }
            let url = {
                1: r.api + '/finance/contract/downLoad/htmlToPdf?contractId=' + this.taskHead.contractId + "&contractModelType=1",
                6: r.api + '/finance/contract/downLoad/htmlToPdf?contractId=' + this.taskHead.contractId + "&contractModelType=4",
                7: r.api + '/finance/contract/downLoad/htmlToPdf?contractId=' + this.taskHead.contractId + "&contractModelType=6",
                2: r.api + '/finance/contract/downLoad/feeToPdf?contractId=' + this.taskHead.contractId + "&type=" + type,
                3: r.api + '/finance/contract/downLoad/additionalDownLoad?contractId=' + this.taskHead.contractId + '&contractAttachType=2',
                8: r.api + '/finance/contract/downLoad/additionalDownLoad?contractId=' + this.taskHead.contractId + '&contractAttachType=6',
                4: r.api + '/finance/contract/downLoad/htmlToPdf?contractId=' + this.taskHead.contractId + "&contractModelType=2",
                5: r.api + '/finance/contract/downLoad/feeToPdf?contractId=' + this.taskHead.contractId + "&type=2",
                9: r.api + '/finance/contract/downLoad/reserveHtmlToPdf?contractModelType=9&reserveContractId=' + this.taskHead.reserveContractId,
                10: r.api + '/finance/contract/downLoad/reserveFeeToPdf?contractModelType=9&reserveContractId=' + this.taskHead.reserveContractId,
                11: r.api + '/finance/contract/downLoad/htmlToPdf?contractId=' + this.taskHead.contractId + "&contractModelType=10",
            }
            window.location.href = url[num];
        },
        // 【合同签约】查看详情
        onContractInfo() {
            window.open("/finance/contract/contractInfo?contractAccess=1&contractId=" + this.taskHead.contractId)
        },
        // 【预定合同】查看详情
        onReserveInfo() {
            window.open("/financial/reserve/reserveInfo?reserveContractId=" + this.taskHead.reserveContractId)
        },
        // 【合同签约】线索详情
        onClueInfo() {
            const { clueId } = this.taskHead;
            if (clueId) { this.clueId = clueId };
            layer.open({
                type: 2,
                title: ["线索详情", 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1600px', '800px'],
                content: "/sale/clue/clueInfo?clueId=" + this.clueId
            });
        },
        // 【合同签约】是否有租赁附件
        getAccFile() {
            const { contractId } = this.taskHead;
            centerApi.isLeaseFile({ contractId: contractId, contractAttachType: 2 }).then(res => {
                if (res.isSuccess == 'yes') {
                    this.accFileFlag = true;
                }
            })

        },
        // 【合同签约】是否有附件
        getWuyeFile() {
            const { contractId } = this.taskHead;
            centerApi.isLeaseFile({ contractId: contractId, contractAttachType: 6 }).then(res => {
                if (res.isSuccess == 'yes') {
                    this.accFileFlag = true;
                }
            })
        },
        // 【工单审批】关联工单
        onRelateForm() {
            layer.open({
                type: 2,
                title: ['工单详情', 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1200px', '800px'], //宽高
                content: '/oa/workflow/workflowInfo?workFormId=' + this.taskHead.relateFormId,
            });
        },

        // 【工单审批】工单审定单上传
        workflowAppUp() {
            layer.open({
                type: 2,
                title: '上传审定单',
                shadeClose: false,
                shade: 0.3,
                area: ['500px', '600px'],
                content: '/common/upload?fileType=1&maxSize=15mb&maxFiles=10',
                btn: ['确定', '取消'],
                yes: (index, layero) => {
                    let fileList = JSON.parse(window[layero.find('iframe')[0]['name']].find());
                    fileList = JSON.parse(JSON.stringify(fileList).replace(/id/g, "fileId"))
                    fileList = this.workflowAppList.concat(fileList);
                    let files = "";
                    for (let i of fileList) {
                        files = [...files, Number(i.fileId)]
                    }
                    utils.ajaxLoad(r.api + '/workForm/saveFiles', {
                        applyApproveId: this.approveId,
                        files: files.toString(),
                        fileType: 2,
                        finishApproveId: null,
                    }, (res) => {
                        if (res.isSuccess == 'yes') {
                            this.$message.success(res.message);
                            layer.close(index);
                            this.getFileList();
                        }
                    })
                }
            });
        },
        // 【工单审批】工单验收单上传
        workflowCheckUp() {
            layer.open({
                type: 2,
                title: '上传验收单',
                shadeClose: false,
                shade: 0.3,
                area: ['500px', '600px'],
                content: '/common/upload?fileType=1&maxSize=15mb&maxFiles=10',
                btn: ['确定', '取消'],
                yes: (index, layero) => {
                    let fileList = JSON.parse(window[layero.find('iframe')[0]['name']].find());
                    fileList = JSON.parse(JSON.stringify(fileList).replace(/id/g, "fileId"))
                    fileList = this.workflowCheckList.concat(fileList);
                    let files = "";
                    for (let i of fileList) {
                        files = [...files, Number(i.fileId)]
                    }
                    utils.ajaxLoad(r.api + '/workForm/saveFiles', {
                        files: files.toString(),
                        fileType: 3,
                        applyApproveId: null,
                        finishApproveId: this.approveId,
                    }, (res) => {
                        if (res.isSuccess == 'yes') {
                            this.$message.success(res.message);
                            layer.close(index);
                            this.getFileList();
                        }
                    })
                }
            });
        },
        // 【工单修改】获取审批修改项
        getNodeParam() {
            let data = {
                approveId: this.approveId,
                processType: this.processType,
            }
            centerApi.getModifyItems(data).then(res => {
                if (res.isSuccess = 'yes') {
                    this.ruleForm = res.data.nodeParams;
                } else {
                    this.ruleForm = "";
                }
            })
        },
        // 【监听】文件上传
        onUpload(val) {
            this.$refs.up.onUpload();
        },

        // 【监听】获取上传文件ID集合
        getFileIds(fileIds) {
            this.getUploadFileList(fileIds);
        },
        // 【请求】获取上传文件信息
        getUploadFileList(fileIds) {
            let data = {
                fileIds,
            };
            getFileList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    const fileArr = res.data
                    const imgType = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'webp', 'psd', 'svg', 'tiff'];
                    const fileType = ['doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf', 'txt', 'zip', 'rar', '7z'];
                    this.comImgList = this.comImgList.concat(fileArr.filter(i => imgType.includes(i.attSuffix)))
                    this.comFileList = this.comFileList.concat(fileArr.filter(i => fileType.includes(i.attSuffix)))
                    const arr = res.data.map((item) => {
                        return item.attId;
                    });
                    this.attIds = arr;
                    this.attIds = [...this.attIds, arr];
                    this.$message.success('上传成功')
                }
            });
        },
        // 【全部评论】更新已读
        CommenUpdate() {
            let data = {
                approveId: this.approveId,
            };
            centerApi.updateRead(data).then(res => { })
        },
        //  【全部评论】文件删除
        onUserIds() {
            this.$confirm('是否要清空通知列表?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.userIds = [];
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            })

        },
        //  【全部评论】文件删除
        onAttIds() {
            this.$confirm('是否要清空附件列表?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.comImgList = [];
                this.comFileList = [];
                this.attIds = [];
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            })

        },
        // 【全部评论】添加评论
        onAddComment: _.debounce(function () {
            let data = {
                approveId: this.approveId,
                content: this.content,
                userIds: this.changeUserIds(this.userIds),
                attIds: this.attIds && this.attIds.join(",")
            }
            centerApi.addComment(data).then(res => {
                if (res.isSuccess == 'yes') {
                    this.content = "";
                    this.userIds = [];
                    this.comImgList = [];
                    this.comFileList = [];
                    this.attIds = [];
                    this.$message.success('评论成功');
                    this.getApproveInfo(); //【请求】流程和评论信息
                }
            })
        }, 500),
        // 【全部评论】撤回评论
        onDelComment(commentId) {
            this.$confirm('是否要撤回该评论?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let data = {
                    commentId
                }
                centerApi.recallComment(data).then(res => {
                    if (res.isSuccess == 'yes') {
                        this.$message.success('评论已撤回');
                        this.getApproveInfo(); //【请求】流程和评论信息
                    }
                })

            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                })
            })

        },

        // 【监听】跳转
        onLink(url) {
            window.open(url);
        },

        // 【监听】查看工程单
        onWorkProjectBill(approveId, assigneeEventAction) {
            let obj = {
                103: "查看预算单",
                104: "查看预算单",
                109: "查看决算单",
                110: "查看内部决算单",
                111: "查看审定单",
                117: "查看二次审定单",
            };
            layer.open({
                type: 2,
                title: [obj[assigneeEventAction], 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1600px', '800px'],
                maxmin: true,
                content: '/oa/workproject/workprojectBillInfo?approveId=' + approveId + "&assigneeEventAction=" + assigneeEventAction,
            });
        },

        // 【监听】查看预算评分表
        onWorkProjectScoring(approveId, eventAction) {
            let URL = '/oa/workproject/pdf/scoringPdf?openType=1&approveId=' + approveId;
            layer.open({
                type: 2,
                title: ["预算评分表", 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1600px', '800px'],
                content: URL,
                // btn: ['下载预算评分表'],
                // yes: function (index, layero) {
                //   window.location.href = URL
                // }
            });
        },

        // 【监听】查看验收确认单
        onWorkProjectCheck(approveId, eventAction) {
            let URL = '/oa/workproject/pdf/checkPdf?openType=1&approveId=' + approveId;
            layer.open({
                type: 2,
                title: ["验收确认单", 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1600px', '800px'],
                content: URL,
                // btn: ['下载验收确认单'],
                // yes: function (index, layero) {
                //   window.location.href = URL
                // }
            });
        },

        // 【监听】巡查日志
        onPatrolLog(approveId) {
            layer.open({
                type: 2,
                title: ["巡查日志", 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1200px', '800px'],
                content: '/oa/workproject/patrolLogList?approveId=' + approveId,
            });
        },

        // 【监听】查看付款账单
        onWorkprojectBillInfo() {
            layer.open({
                type: 2,
                title: ["付款账单明细", 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1600px', '800px'],
                content: '/oa/workproject/bill/workprojectApproveBillInfo?approveId=' + this.approveId,
            });
        },

        // 【监听】施工单位台账概况
        onWorkprojectLedger() {
            window.open('/oa/workproject/chart/constructionLedger?manageId=' + this.taskHead.constructionUnitId)
        },

        // 【监听】园区台账概况
        onConstructionLedger() {
            window.open('/oa/workproject/chart/projectLedger?projectId=' + this.taskHead.projectId)
        },

        // 【监听】审核附件整合下载
        onAllWorkProjectFile() {
            function downloadPDF(url, filename) {
                fetch(url)
                    .then(response => response.blob())
                    .then(blob => {
                        saveAs(blob, filename);
                    })
            };
            let data = {
                approveId: this.approveId
            };
            centerApi.downloadFiles(data).then(res => {
                if (res.isSuccess == 'yes') {
                    downloadPDF(res.data.downloadUrl, res.data.downloadFileName)
                }
            })
        },

        // 【监听】下载项目附件
        onWorkProjectFile() {
            layer.open({
                type: 2,
                title: ["项目附件下载", 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['1400px', '800px'],
                content: '/oa/workproject/workprojectFile?approveId=' + this.approveId,
            });
        },

        // 【监听】结佣附件下载
        onAccountFiles(title) {
            layer.open({
                type: 2,
                title: [title, 'text-align: center;padding:0;font-size:18px;background:#fff'],
                shadeClose: false,
                closeBtn: 2,
                shade: 0.3,
                area: ['600px', '300px'],
                content: '/partner/commission/accountFiles?approveId=' + this.approveId + "&processType=" + this.processType,
            });
        },

        // 【监听】结佣详情
        onAccountInfo() {
            const { contractCommissionId } = this.taskHead;
            let url = "/partner/commission/accountInfo?contractCommissionId=" + contractCommissionId;
            window.open(url)
        },

        // 【监听】查看合伙人详情
        onPartnerInfo() {
            const URL = "/partner/company/partnerInfo?partnerId=" + this.taskHead.partnerId;
            window.location.href = URL;
        },

        // 【监听】查看相关结佣审批
        onApproveInfo() {
            const URL = '/oa/approve/normalApprove/myTask?processType=103&approveId=' + this.taskHead.contractCommissionApproveId
            window.open(URL)
        },
    }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/onePlay/myTask.scss';
@import '@/assets/styles/layout.scss';

.pointer {
    cursor: pointer;
}

.cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;

}

.table-footer {
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;

}

.at-users {
    padding: 15px;
}

.my-right {
    border-left: 1px solid#e8e8e8;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
}
</style>