<template>
    <div id="app">
        <el-dialog :title="'提交审批'" :visible.sync="isShowApprove" width="40%">
            <div class="app-bg">
                <div class="app-bg-white">
                    <!-- 中 -->
                    <div class="app-main">
                        <el-timeline>
                            <el-timeline-item v-for="(item, index) in flowList" :key="index">
                                <div class="detail_item">
                                    <!-- 审核人 -->
                                    <template v-if="item.nodeType == 2">
                                        <!-- 指定成员会签 -->
                                        <div class="item_left"
                                            v-if="item.assigneeType == 2 && item.assigneeMethod == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet && item.spUserVoSet.length }}人会签</span>
                                        </div>
                                        <!-- 指定成员或签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 2 && item.assigneeMethod == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet && item.spUserVoSet.length }}人审核</span>
                                        </div>
                                        <!-- 角色会签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 3 && item.assigneeMethod == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.roleName }}会签</span>
                                        </div>
                                        <!-- 角色或签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 3 && item.assigneeMethod == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.roleName }}或签</span>
                                        </div>
                                        <!-- 主管会签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 4 && item.assigneeMethod == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.assigneeLeaderLevel }}级主管会签</span>
                                        </div>
                                        <!-- 主管或签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 4 && item.assigneeMethod == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.assigneeLeaderLevel }}级主管或签</span>
                                        </div>
                                        <!-- 业务角色 -->
                                        <div class="item_left" v-else-if="item.assigneeType == 6">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.roleName }}</span>
                                        </div>
                                        <!-- 发起人自己 -->
                                        <div class="item_left" v-else-if="item.assigneeType == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>发起人自己</span>
                                        </div>
                                        <!-- 发起人自选全公司 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 5 && item.assigneeSelectRange == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet == null ? '请选择审核人' : '1人审核' }}</span>
                                        </div>
                                        <!-- 发起人自选角色里选 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 5 && item.assigneeSelectRange == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet == null ? '请选择审核人' : '1人审核' }}</span>
                                        </div>
                                        <!-- 单独人审核 -->
                                        <div class="item_left"
                                            v-else-if="item.spUserVoSet && item.spUserVoSet.length == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet && item.spUserVoSet.length }}人审核</span>
                                        </div>
                                    </template>
                                    <!-- 办理人 -->
                                    <template v-else-if="item.nodeType == 3">
                                        <!-- 指定成员会签 -->
                                        <div class="item_left"
                                            v-if="item.assigneeType == 2 && item.assigneeMethod == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet && item.spUserVoSet.length }}人会签</span>
                                        </div>
                                        <!-- 指定成员或签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 2 && item.assigneeMethod == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet && item.spUserVoSet.length }}人办理</span>
                                        </div>
                                        <!-- 角色会签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 3 && item.assigneeMethod == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.roleName }}会签</span>
                                        </div>
                                        <!-- 角色或签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 3 && item.assigneeMethod == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.roleName }}或签</span>
                                        </div>
                                        <!-- 主管会签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 4 && item.assigneeMethod == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.assigneeLeaderLevel }}级主管会签</span>
                                        </div>
                                        <!-- 主管或签 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 4 && item.assigneeMethod == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.assigneeLeaderLevel }}级主管或签</span>
                                        </div>
                                         <!-- 业务角色 -->
                                         <div class="item_left" v-else-if="item.assigneeType == 6">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.roleName }}</span>
                                        </div>
                                        <!-- 发起人自己 -->
                                        <div class="item_left" v-else-if="item.assigneeType == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>发起人自己</span>
                                        </div>
                                        <!-- 发起人自选全公司 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 5 && item.assigneeSelectRange == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet == null ? '请选择办理人' : '1人办理' }}</span>
                                        </div>
                                        <!-- 发起人自选角色里选 -->
                                        <div class="item_left"
                                            v-else-if="item.assigneeType == 5 && item.assigneeSelectRange == 2">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet == null ? '请选择办理人' : '1人办理' }}</span>
                                        </div>
                                        <!-- 单独人办理人 -->
                                        <div class="item_left"
                                            v-else-if="item.spUserVoSet && item.spUserVoSet.length == 1">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span>{{ item.spUserVoSet && item.spUserVoSet.length }}人办理</span>
                                        </div>
                                    </template>
                                    <!-- 抄送人 -->
                                    <template v-else-if="item.nodeType == 4">
                                        <div class="item_left">
                                            <h4>{{ item.nodeName }}</h4>
                                            <span v-if="item.spUserVoSet">抄送{{ item.spUserVoSet &&
                                                item.spUserVoSet.length
                                            }}人</span>
                                            <span v-else>{{ item.roleName }}</span>
                                        </div>
                                    </template>
                                    <div class="item_right">
                                        <div v-for="(v, k) in item.spUserVoSet" class="avatar_item" :key="k">
                                            <div class="avatar_left">
                                                <img :src="v.headPicUrl ? v.headPicUrl : defaultAvatar"
                                                    alt="" class="avatar">
                                                <span style="margin-top: 3px;">{{ v.fullName }}</span>
                                                <i class="el-icon-close" @click="delAvatar(item, index)"
                                                    v-if="(item.nodeType == 2 && item.assigneeType == 5) || (item.nodeType == 3 && item.assigneeType == 5)"></i>
                                            </div>
                                            <i class="el-icon-plus" style="margin: 0 5px;"></i>
                                        </div>
                                        <template
                                            v-if="item.nodeType != 4 && (item.nodeType == 2 && item.assigneeType == 5) || (item.nodeType == 3 && item.assigneeType == 5)">
                                            <div class="add"
                                                v-if="item.spUserVoSet == null || item.spUserVoSet.length < 1">
                                                <i class="el-icon-plus" @click="add(item, index)"></i>
                                                <span class="red">*</span>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>
            </div>
            <!-- 下 -->
            <div class="app-foot">
                <el-button type="primary" @click="submitForm()">提交</el-button>
            </div>
            <!-- 弹框 -->
            <el-drawer :title="selectNodeName" :visible.sync="dialogFormVisible" direction="rtl" :modal="false">
                <el-radio-group v-model="checkList">
                    <el-radio :label="u.candidateUserId" v-for="(u, i) in checkData" :key="i"><img class="peoImg"
                            src="../../../assets/images/icon_people.png">{{ u.candidateUserName }}</el-radio>
                </el-radio-group>
                <div class="dialog-footer">
                    <el-button type="primary" @click="sure">确 定</el-button>
                </div>
            </el-drawer>

        </el-dialog>
    </div>
</template>
<script>
import centerApi from "@/api/examine/center.js";
export default {
    props: {
        isShowApprove: {
            type: Boolean,
            default: false
        },
        processParams: {
            type: Object,
            default: () => { }
        },
    },
    data() {
        return {
            defaultAvatar: require('../../../assets/images/examine/default.png'),
            flowList: [], //流程展示数据
            approveData: [],
            dialogFormVisible: false,
            checkList: '',
            checkData: [], // 抄送人
            checkId: [],
            selectNodeName: '',
            nodeIndex: '',
            addNodeName: [], // 用户后来添加的审核人或者办理人
            assigneeBusinessRoleObj: {
                1: "归属项目发起人",
                2: "归属园区一级主管",
                3: "归属园区二级主管",
                4: "归属园区出纳",
                5: "归属项目施工监理",
                6: "归属项目预算设计师",
            }
        }
    },
    created() {
    },
    watch: {
        isShowApprove: function (val) {
            if (val) {
                this.getProcessId()
            }
        }
    },
    methods: {
        // 选中的流程id
        getProcessId() {
            let _this = this;
            centerApi.processInfo(this.processParams).then(res => {
                if (res.isSuccess == 'yes') {
                    let data = res.data
                    _this.flowList = data.slice(1, data.length - 1)
                } else {
                    _this.flowList = [];
                    this.$message.error(res.message);
                }
            })

        },
        // 添加
        add(e, index) {
            this.nodeIndex = index;
            this.selectItem = e
            this.dialogFormVisible = true;
            if (e.nodeType == 2) {
                this.selectNodeName = '选择审核人'
            } else {
                this.selectNodeName = '选择办理人'
            }
            centerApi.examineList({
                kw: '',
                roleId: e.assigneeSelectRange == 1 ? '' : e.assigneeSelectRole
            }).then(res => {
                if (res.isSuccess == 'yes') {
                    this.checkList = ''
                    this.checkData = res.data;
                }
            })
        },
        delAvatar(item, index) {
            item.spUserVoSet = null;
            this.$forceUpdate()
            const i=this.addNodeName.findIndex(item => item.nodeId == item.nodeId)
            this.addNodeName.splice(i, 1)
        },
        sure() {
            this.flowList.forEach((v, k) => {
                if (k == this.nodeIndex) {
                    this.checkData.forEach((item, index) => {
                        if (item.candidateUserId == this.checkList) {

                            if (v.spUserVoSet == null) {
                                v.spUserVoSet = [];
                                v.spUserVoSet.push({
                                    assigneeRoleId: this.checkList,
                                    headPicUrl: item.candidateUserHeadUrl,
                                    fullName: item.candidateUserName
                                });
                                this.addNodeName.push({
                                    nodeId: v.nodeId,
                                    assigneeRoleId: this.checkList
                                })
                            }
                        }
                    })
                }
            })
            this.dialogFormVisible = false;
        },

        //提交按钮
        submitForm: _.throttle(function () {
            let _this = this;
            if (_this.processParams.processId == '' && _this.processType) {
                _this.$message.warning('请选择流程');
            } else {
                let arr1 = [];
                let arr1Len = 0;
                // 检查流程必填项
                _this.flowList.forEach((v, k) => {
                    if ((v.nodeType == 2 || v.nodeType == 3) && (v.assigneeSelectRange == 1 || v.assigneeSelectRange == 2)) {
                        arr1.push(v);
                        arr1Len = arr1.length
                    }
                    if (v.nodeType == 2 && (v.assigneeSelectRange == 1 || v.assigneeSelectRange == 2) && v.spUserVoSet == null) {
                        _this.$message.warning('请选择审核人');

                    } else if (v.nodeType == 3 && (v.assigneeSelectRange == 1 || v.assigneeSelectRange == 2) && v.spUserVoSet == null) {
                        _this.$message.warning('请选择办理人');

                    } else {

                    }
                })
                let picIds = []
                if (window.parent.location.pathname == "/oa/budget/budgetUse") {
                    picIds = window.parent.getPicIds()
                }
                let nodeSelectUserMap = {}
                _this.addNodeName.forEach((u, i) => {
                    nodeSelectUserMap[u.nodeId] = u.assigneeRoleId
                })
                if (Object.keys(nodeSelectUserMap).length == arr1Len) {
                    let data = {
                        businessId: _this.processParams.businessId,
                        processId: _this.processParams.processId,
                        relationType: _this.processParams.relationType,
                        params: {},
                        fileIds: picIds,
                        nodeSelectUserMap
                    }
                    centerApi.startApprove(data).then(res => {
                        if (res.isSuccess == 'yes') {
                            _this.$message.success(res.message);
                            setTimeout(function () {
                                _this.$emit('closeDialog')
                            }, 800)
                        } else {
                            _this.$message.error(res.message);
                        }
                    })

                }
            }
        }, 3000)
    }
}
</script>
<style scoped>
.label-icon {
    color: red;
    margin-right: 2px;
    display: inline-block;
    vertical-align: middle;
}
.dialog-footer{
margin: 20px;

}
.app-bg {
    flex: 1;
    padding: 24px;
    background-color: #F0F2F5;
    overflow: auto;
}

.app-bg-white {
    background-color: #FFF;
    height: 100%;
    overflow: auto;
}

.app-top {
    padding: 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.app-main {
    height: 5.6rem;
    padding: 24px 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.app-foot {
    height: 60px;
    padding: 0 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
}

/* =================老的================ */

.content-wrapper {
    background: #eee;
}

.box {
    position: relative;
    border-radius: 3px;
    background: #ffffff;
    border-top: 0;
    margin-bottom: 20px;
    width: 100%;
    box-shadow: none;
}

#imgList {
    display: flex;
}

#imgList .fileimg {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    cursor: pointer;
    color: #0076a9;
}

.layui-upload {
    display: flex;
}

.peoDialog .el-checkbox-group {
    display: flex;
    flex-direction: column;
    height: 310px;
    overflow: auto;
}

.peoDialog .el-dialog__body {
    padding: 25px 0;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
}

.peoDialog .el-checkbox-group .el-checkbox {
    margin-bottom: 15px;
}

.peoImg {
    width: 21px;
    height: 21px;
    margin-right: 8px;
}

.el-tag {
    margin-right: 15px;
}

.img:hover img {
    background-color: rgba(0, 0, 0, .4);
}

.img {
    border-radius: 5px;
}

.fileimg {
    width: 120px;
    height: 120px;
    float: left;
    position: relative;
    margin-right: 15px;
}

.fileimg em {
    display: none;
}

.fileimg:hover em {
    display: block;
    position: absolute;
    width: 120px;
    height: 120px;
    background-color: rgba(0, 0, 0, .4);
    top: 0;
    left: 0;
    font-size: 20px;
    text-align: center;
    line-height: 120px;
    color: #fff;
}
.el-timeline{
    width: 80%;
}
.el-timeline-item__tail {
    border-left: 4px solid #E4E7ED;
    left: 3px;
}

.el-timeline-item__content {
    border-bottom: 1px solid #eee;
    padding-bottom: 20px;
}

.detail_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.detail_item .item_left h4 {
    font-size: 22px;
    margin-bottom: 6px;
}

.detail_item .item_left span {
    color: #8585A3;
}

.detail_item .item_right {
    position: relative;
}

.detail_item .item_right .add .el-icon-plus {
    font-size: 30px;
    border-radius: 50%;
    border: 1px dashed;
    width: 48px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    color: #409EFF;
    font-weight: 600;
    cursor: pointer;
}

.detail_item .item_right .red {
    color: red;
    font-size: 25px;
    position: absolute;
    right: 2px;
    top: -7px;
}

.el-timeline-item__node {
    background: #409EFF;
}

.detail_item .item_right {
    display: flex;
}

.detail_item .item_right .avatar_item {
    display: flex;
    align-items: center;
}

.detail_item .item_right .avatar_item:last-child .el-icon-plus {
    display: none;
}

.detail_item .item_right .avatar_item .avatar_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.detail_item .item_right .avatar_item .avatar {
    width: 55px;
    height: 55px;
    border-radius: 50%;
}

.people .el-radio-group {
    display: flex;
    flex-direction: column;
    height: 240px;
    overflow-y: auto;
}

.el-radio {
    padding: 5px 25px;
}

.el-radio:hover {
    background-color: #ecf5ff;
}

.avatar_left .el-icon-close {
    background: #000;
    color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    position: absolute;
    right: 3px;
    top: -4px;
    cursor: pointer;
}

.content-top {
    position: fixed;
    display: flex;
    justify-content: center;
    padding: 16px;
    background: #f8f8f8;
    z-index: 999;
    top: 0;
    width: 100%;
}

.content-top .el-icon-close {
    position: absolute;
    right: 23px;
    top: 17px;
    font-size: 22px;
    cursor: pointer;
}
</style>