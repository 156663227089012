<template>
    <div>
        <el-button :type="title == '查看详情' ? 'text' : 'primary'" @click.stop="onAdd">{{ title }}</el-button>
        <el-dialog :title="title" :visible.sync="workOrderEditShow" width="50%" @close="reset" :close-on-click-modal="false" append-to-body>
            <el-form :model="params" :rules="rules" ref="form-ref" :disabled="openType == 2">
                <div class="form-main">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="工单类型" prop="workSheetTypeId">
                                <el-select v-model="params.workSheetTypeId" placeholder="请选择工单类型"
                                    @change="onWorkSheetType" :disabled="openType == 2 || openType == 3">
                                    <el-option v-for="(item, index) in workSheetTypeList" :label="item.workSheetName"
                                        :value="item.workSheetTypeId" :key="index">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="工单模板" prop="templateId">
                                <el-select v-model="params.templateId" placeholder="请选择工单模板" @change="onTemplate"
                                    :disabled="openType == 2">
                                    <el-option v-for="(item, index) in templateList" :label="item.workSheetName"
                                        :value="item.templateId" :key="index">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <!-- 动态数据表单 -->
                    <div v-for="(item, index) in params.workSheetInstanceParamDtoList" :key="item.templateParamId"
                        style="display: inline-block;width: 50%;">
                        <!-- 输入框-整数 -->
                        <el-form-item v-if="item.inputType == 1" :label="item.paramName"
                            :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                clearable></el-input>
                        </el-form-item>
                        <!-- 输入框-浮点 -->
                        <el-form-item v-if="item.inputType == 2" :label="item.paramName"
                            :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                clearable></el-input>
                        </el-form-item>
                        <!-- 输入框-文本 -->
                        <el-form-item v-if="item.inputType == 3" :label="item.paramName"
                            :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input v-model.trim="item.paramValue" autocomplete="off" :maxlength="item.maxlength"
                                :placeholder="item.placeholder" :disabled="item.disabled" @input="onChange"
                                clearable></el-input>
                        </el-form-item>
                        <!-- 输入框-文本域 -->
                        <el-form-item v-if="item.inputType == 4" :label="item.paramName"
                            :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-input type="textarea" v-model.trim="item.paramValue" autocomplete="off"
                                :maxlength="item.maxlength" :placeholder="item.placeholder" :disabled="item.disabled"
                                @input="onChange" clearable autosize></el-input>
                        </el-form-item>
                        <!-- 选择器-单选 -->
                        <el-form-item v-if="item.inputType == 5" :label="item.paramName"
                            :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-select v-model="item.paramValue" :placeholder="item.placeholder"
                                :disabled="item.disabled" :filterable="item.canSearch" @change="onChange">
                                <el-option v-for="(it, index) in item.optionVoList" :label="it.value" :value="it.key"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 选择器-多选 -->
                        <el-form-item v-if="item.inputType == 6" :label="item.paramName"
                            :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-select v-model="item.paramValue" :placeholder="item.placeholder"
                                :disabled="item.disabled" :filterable="item.canSearch" @change="onChange" multiple>
                                <el-option v-for="(it, index) in item.optionVoList" :label="it.value" :value="it.key"
                                    :key="index"></el-option>
                            </el-select>
                        </el-form-item>
                        <!-- 选择器-日期 -->
                        <el-form-item v-if="item.inputType == 7" :label="item.paramName"
                            :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                            :rules="workorderRules(item)">
                            <el-date-picker v-model="item.paramValue" type="date" :placeholder="item.placeholder"
                                :picker-options="item.dateLimit ? pickerOptions : {}" :disabled="item.disabled"
                                @change="onChange" :editable="false" value-format="yyyy-MM-dd">
                            </el-date-picker>
                        </el-form-item>
                        <!-- 上传 -->
                        <div v-if="item.inputType == 8">
                            <el-form-item :label="item.paramName"
                                :prop="'workSheetInstanceParamDtoList.' + index + '.paramValue'"
                                :rules="workorderRules(item)">
                                <div class="upload">
                                    <!-- 上传图标 -->
                                    <span class="upload-icon" @click="onUpload(item, index)"
                                        v-if="item.fileList.length < item.limitLength&&openType != 2">
                                        <i class="el-icon-upload2"></i>
                                    </span>
                                    <!-- 文件列表 -->
                                    <li class="upload-item" v-for="i in item.fileList" :key="i.attId">
                                        <i class="el-icon-circle-close" @click="onDelFile(i, index)"
                                            v-if="openType != 2"></i>
                                        <el-image class="upload-box" v-if="readFileType(i.fileName)" :src="i.fileUrl"
                                            :preview-src-list="[i.fileUrl]">
                                        </el-image>
                                        <a class="upload-box" v-else :href="i.fileUrl">
                                            <img src="../../../assets/images/examine/wd.png" :alt="i.fileName">
                                        </a>
                                    </li>
                                </div>

                            </el-form-item>
                        </div>

                    </div>
                </div>
                <div class="form-footer">
                    <el-button type="primary" @click="onSubmit('form-ref')" v-if="openType == 1">发起审批</el-button>
                </div>
            </el-form>
        </el-dialog>
        <UpLoad @fileIds="getFileIds" ref="up" :fileType="transfor(currentFileType)" :maxlimit="currentMaxLimit">
        </UpLoad>
        <WorkOrderApproveDialog :isShowApprove="isShowApprove" :processParams="processParams"
            @closeDialog="closeDialog"></WorkOrderApproveDialog>
    </div>
</template>
<script>
import { getWorkSheetTemplateList } from "@/api/examine/model.js";
import { getFileList } from "@/api/common";
import { workorderRules } from "@/utils/utils.js";
import centerApi from "@/api/examine/center.js";
import UpLoad from "@/components/upload";
import WorkOrderApproveDialog from "@/views/examine/child/workorderApproveDialog.vue";
export default {
    name: 'workSheetDialog',
    props: ['title', 'row'],
    components: { UpLoad, WorkOrderApproveDialog },

    data() {
        return {
            workorderRules,
            openType: 1, //1[新建],2[编辑],3[工程账单]
            workSheetTypeList: [], //工单类型列表
            templateList: [], //工单模板列表
            // from表单
            params: {
                workSheetTypeId: "", //工单类型
                sheetInstanceId: "", //工单ID
                workSheetType: "", //工单类型
                sheetBillIdList: "", //账单ID集合
                applyPayId: "",
                templateId: "",
                workSheetInstanceParamDtoList: [], //工单参数
            },
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
                },
            },
            workOrderEditShow: false,
            rules: {
                workSheetTypeId: [{
                    required: true,
                    message: '请选择工单类型',
                    trigger: 'change'
                }],
                templateId: [{
                    required: true,
                    message: '请选择工单模板',
                    trigger: 'change'
                }]
            },
            currentIndex: 0,
            currentMaxLimit: 1,
            currentFileType: 1,//1: "图片",2: "文件",3: "不限"
            isShowApprove: false,
            processParams: {
                processId: "",
                businessId: "",
                relationType: 1//1:工单，2:工程
            },
            imgType: ['jpg', 'gif', 'png', 'bmp', 'jpeg', 'JPG', 'GIF', 'PNG', 'BMP', 'JPEG'], //图片类型
        }
    },
    mounted() {

    },
    methods: {
        onAdd() {
            this.workOrderEditShow = true;
            this.openType = 1;
            this.getWorkSheetTypes();

            if (this.row) {
                this.params = this.row
                this.openType = 2
                this.getTemplateList()
            }

        },
        transfor(type) {
            if (this.currentMaxLimit > 1) {
                if (type == 1) {
                    return 2//多图上传
                } else if (type == 2) {
                    return 4//多文件上传
                } else {
                    return 3//图文上传
                }
            } else {
                if (type == 1) {
                    return 1//单图上传
                } else if (type == 2) {
                    return 5//单文件上传
                } else {
                    return 3//图文上传
                }
            }
        },
        // 【识别】文件类型
        readFileType(url) {
            url = url.split('.')
            let type = url[url.length - 1]
            if (type == "jpg" || type == "gif" || type == "png" || type == "bmp") {
                return true
            } else {
                return false
            }
        },

        // 【请求】工单类型下拉列表
        getWorkSheetTypes() {
            let data = {};
            centerApi.projectType(data).then(res => {
                if (res.isSuccess == 'yes') {
                    this.workSheetTypeList = res.data;
                    if (this.openType == 2) {
                        this.getTemplateList()
                    }
                }
            })

        },

        // 【请求】工单模板下拉列表
        getTemplateList() {
            this.params.workSheetType = this.workSheetTypeList.find(item => item.workSheetTypeId == this.params.workSheetTypeId).workSheetType
            let data = {
                workSheetTypeId: this.params.workSheetTypeId,
                attributionType: this.workSheetTypeList.find(item => item.workSheetTypeId == this.params.workSheetTypeId).attributionType

            };
            getWorkSheetTemplateList(data).then(res => {
                if (res.isSuccess == 'yes') {
                    this.templateList = res.data.list;
                    if (this.openType == 2) {
                        this.getTemplateInfo()
                    }
                }
            })

        },

        // 【请求】工单模板回显(新建)
        getTemplateInfo() {
            let data = {}
            if (this.openType == 1) {
                data = {
                    templateId: this.params.templateId
                };
            } else {
                data = {
                    sheetInstanceId: this.params.sheetInstanceId
                };
            }

            centerApi.workSheetTemplate(data).then(res => {
                if (res.isSuccess == 'yes') {
                    if (this.openType == 2) {
                        this.params = res.data;
                    }
                    if (res.data.workSheetInstanceParamVoList) {
                        for (let i of res.data.workSheetInstanceParamVoList) {
                            i.maxlength = i.maxlength && i.maxlength.toString();
                        };
                        this.params.workSheetInstanceParamDtoList = res.data.workSheetInstanceParamVoList;
                        if (this.openType == 2) {
                            this.params.workSheetTypeId = this.row.workSheetTypeId
                        }
                    }
                }
            })

        },

        // 【监听】新增工单
        getWorkOrderAdd() {
            let data = JSON.parse(JSON.stringify(this.params));
            data.sheetBillIdList = data.sheetBillIdList.split(',');
            for (let i of data.workSheetInstanceParamDtoList) {
                i.paramValue = i.paramValue && i.paramValue.toString();
            };
            centerApi.addWorkSheet(data).then(res => {
                this.processParams = {
                    processId: res.data.processId,
                    businessId: res.data.sheetInstanceId,
                    relationType: 1
                }
                this.isShowApprove = true

            })

        },


        // 【监听】编辑工单
        getWorkOrderEdit() {
            let data = this.params;
            for (let i of data.workSheetInstanceParamDtoList) {
                i.paramValue = i.paramValue && i.paramValue.toString();
            }
            centerApi.updateWorkSheet(data).then(res => {
                this.$message({
                    message: res.message,
                    type: 'success'
                });
                this.workOrderEditShow = false;

            })
        },


        // 【监听】强制更新
        onChange() {
            this.$forceUpdate()
        },

        // 【监听】工单类型选择
        onWorkSheetType() {
            this.getTemplateList();
            this.params.templateId = "";
            this.workSheetInstanceParamVoList = [];
        },

        // 【监听】工单类型选择
        onTemplate() {
            this.getTemplateInfo();
        },
        // 【监听】文件上传
        onUpload(item, indexs) {
            this.currentIndex = indexs;
            this.currentMaxLimit = item.limitLength;
            this.currentFileType = item.uploadType;
            console.log(this.currentMaxLimit);
            this.$refs.up.onUpload();
        },
        // 【监听】获取上传文件ID集合
        getFileIds(fileIds) {
            let obj = this.params.workSheetInstanceParamDtoList[this.currentIndex];
            obj.paramValue = fileIds;
            this.getFileList(fileIds);
        },
        // 【请求】获取文件信息
        getFileList(fileIds) {
            let data = {
                fileIds,
            };
            getFileList(data).then((res) => {
                if (res.isSuccess == "yes") {
                    let obj = this.params.workSheetInstanceParamDtoList[this.currentIndex];
                  
                    let arr = []
                    res.data.forEach(i => {
                        arr.push({
                            fileId: i.attId,
                            fileName: i.attName+'.'+i.attSuffix,
                            fileUrl: i.url

                        })
                    });
                    obj.fileList = obj.fileList.concat(arr);
                    console.log(obj.fileList);
                }
            });
        },
        reset() {
            this.params = {
                workSheetTypeId: "", //工单类型ID
                sheetInstanceId: "", //工单ID
                workSheetType: "", //工单类型
                sheetBillIdList: "", //账单ID集合
                applyPayId: "",
                templateId: "",
                workSheetInstanceParamDtoList: [], //工单参数
            }
            this.templateList = []
            this.$nextTick(() => {
                this.$refs['form-ref'].clearValidate();
            })
            this.isShowApprove = false
            this.workOrderEditShow = false
        },
        closeDialog() {
            
            this.reset()
            this.$emit('refresh')
        },

        // 【监听】附件删除
        onDelFile(item, indexs) {
            let obj = this.params.workSheetInstanceParamDtoList[indexs];
            let index = obj.fileList.findIndex(i => i.fileId == item.fileId);
            obj.fileList.splice(index, 1);
            if (obj.fileList) {
                let arr = []
                for (let i of obj.fileList) {
                    arr = [...arr, i.fileId]
                }
                obj.paramValue = arr.toString();
            }
        },

        // 【监听】表单提交
        onSubmit(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 新建
                    if (this.openType == 1) {
                        this.getWorkOrderAdd();
                    }
                    // 编辑
                    else if (this.openType == 2) {
                        this.getWorkOrderEdit();
                    }
                    // 工程账单
                    else if (this.openType == 3) {
                        this.getWorkOrderAdd();
                    }
                } else {
                    return false;
                }
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.el-form {
    display: block;
}

.form-footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .red {
        color: red;
        margin-top: 10px;
    }
}

/* 上传 */
.upload {
    width: 5rem;
    display: flex;
    flex-wrap: wrap;
}

/* 上传图标 */
.upload-icon {
    width: 80px;
    height: 80px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid #eee;
    cursor: pointer;
    margin: 0 15px 15px 0;
}

.layui-icon-addition {
    font-size: 36px;
    color: #D3D3D3;
}


/* 文件列表 */
.upload-item {
    height: 80px;
    display: inline-block;
    position: relative;
    outline: 1px dashed #C0C0C0;
    margin: 0 15px 15px 0;

    i {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
        color: #808080;
        font-size: 20px;
        z-index: 99999;
    }


}

.layui-icon-close-fill {
    position: absolute;
    top: -19px;
    right: -12px;
    display: none;
    cursor: pointer;
    color: #808080;
    font-size: 24px;
    z-index: 99;
}

.upload-item:hover .layui-icon-close-fill {
    display: block;
}

.upload-box {
    width: 80px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
</style>