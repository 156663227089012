// 工单中心相关接口
import request from "@/utils/request";
import Export from '@/utils/export';

 // 【请求】表格数据
 const  tableData=function (data) {
  return request("post", "/apm/workSheet/instance/approvePage", data);
}
// 【请求】表格数据导出
const  exportList=function (data) {
  return Export("post", "/apm/workSheet/instance/approveExport", data,'工单列表');
}
// 获取项目类型
const  projectType=function (data) {
  return request("post", "/apm/workSheet/template/workSheetCommonTypes", data);
}
// 【请求】工单模板回显(新建)
const  workSheetTemplate=function (data) {
  return request("post", "/apm/workSheet/instance/edit", data);
}
// 添加工单
const  addWorkSheet=function (data) {
  return request("post", "/apm/workSheet/instance/add", data);
}
// 删除工单
const  deleteWorkSheet=function (data) {
  return request("post", "/apm/workSheet/instance/del", data);
}
// 修改工单
const  updateWorkSheet=function (data) {
  return request("post", "/apm/workSheet/instance/update", data);
}
// 测试启动流程
const  processInfo=function (data) {
  return request("post", "/apm/oa/approve/startTest", data);
}
// 审核人员列表
const  examineList=function (data) {
  return request("post", "/apm/oa/process/node/findEmpList", data);
}
// 启动审批
const  startApprove=function (data) {
  return request("post", "/apm/oa/approve/start", data);
}
// 请求】用户信息
const userInfo=function (data) {
  return request("get", "/apa/user/userInfo", data);
}

// 【请求】豆腐块数据
const tofuCount=function (data) {
  return request("post", "/apm/oa/approve/myTaskStatusCnt", data);
}
// 通过申请id 查询审批申请明细
const topInfo=function (data) {
  return request("post", "/apm/oa/approve/approveDetailInfo", data);
}
// 【请求】节点信息
const queryProcessDetail=function (data) {
  return request("post", "/apm/oa/approve/approveExecutionInfo", data);
}
// 【请求】附件信息
const queryAttachments=function (data) {
  return request("post", "/apm/oa/approve/fileList", data);
}
// 【请求】流程和评论信息
const queryProcessAndComment=function (data) {
  return request("post", "/apm/oa/approve/approveExecutionMergeComment", data);
}
// 【请求】租赁价格表格
const queryLeasePrice=function (data) {
  return request("post", "/apm/oa/contract/new/getContractRentPrice", data);
}
// 【请求】相似房源表格
const querySimilarHouse=function (data) {
  return request("post", "/apm/oa/contract/new/contractLikeResource", data);
}
// 【审批流程】撤回
const recall=function (data) {
  return request("post", "/apm/oa/approve/undo", data);
}
// 【审批流程】拒绝
const reject=function (data) {
  return request("post", "/apm/oa/approve/reject", data);
}
// 【审批流程】通过
const pass=function (data) {
  return request("post", "/apm/oa/approve/accept", data);
}
// 【审批流程】提交
const submit=function (data) {
  return request("post", "/apm/oa/approve/submit", data);
}
// 【请求】回退
const rollback=function (data) {
  return request("post", "/apm/oa/approve/goBackToThePreviousStep", data);
}
// 【审批流程】转交
const transfer=function (data) {
  return request("post", "/apm/oa/approve/transfer", data);
}
// 【合同签约】是否有附件
const isLeaseFile=function (data) {
  return request("post", "/apm/oa/finance/contract/downLoad/additional", data);
}
// 【工单修改】获取审批修改项
const getModifyItems=function (data) {
  return request("post", "/apm/oa/getNodeParam", data);
}
// 【全部评论】是否开放评论区
const isComment=function (data) {
  return request("post", "/apm/oa/comment/exist", data);
}
// 【全部评论】更新已读
const updateRead=function (data) {
  return request("post", "/apm/oa/comment/update", data);
}
// 【全部评论】文件上传
const uploadFile=function (data) {
  return request("post", "/file/uploadBase64", data);
}
// 【全部评论】添加评论
const addComment=function (data) {
  return request("post", "/apm/oa/comment/create", data);
}
// 【全部评论】撤回评论
const recallComment=function (data) {
  return request("post", "/apm/oa/comment/remove", data);
}
// 【监听】审核附件整合下载
const downloadFile=function (data) {
  return request("post", "/workProject/instance/mergeFilesByApprove", data);
}
const centerApi={
  tableData,
  exportList,
  projectType,
  workSheetTemplate,
  deleteWorkSheet,
  addWorkSheet,
  updateWorkSheet,
  processInfo,
  examineList,
  startApprove,
  userInfo,
  tofuCount,
  topInfo,
  queryProcessDetail,
  queryAttachments,
  queryProcessAndComment,
  queryLeasePrice,
  querySimilarHouse,
  recall,
  reject,
  pass,
  submit,
  rollback,
  transfer,
  isLeaseFile,
  getModifyItems,
  isComment,
  updateRead,
  uploadFile,
  addComment,
  recallComment,
  downloadFile,
}
export default centerApi